import React, { useState, useEffect } from "react";
import AddparamForm from "./AddparamForm";
import "./addParamDialog.scss";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { ParamDefinitionEntity } from "../../../../domain/entities/ParamDefinitionEntity";
import { BsSearch } from "react-icons/bs";

function AddParamDialog({
  chars,
  insertMention,
  index,
  search,
  handleClose,
  allParams,
}) {
  const [insertNew, setInsertNew] = useState(false);
  const [selectedChar, setSelectedChar] = useState(null);
  const [existingParam, setExistingParam] = useState(true);
  const [currentSearch, setSearch] = useState("");
  const [filteredChars, setFilteredChars] = useState(chars);
  const { t, language } = useTranslation();
  const [filteredAllParams, setFilteredAllParams] =
    useState<ParamDefinitionEntity[]>(allParams);
  const translationPath = "pages.contractTemplateParam.addParamForm";
  useEffect(()=>{
   setSearch(search)
  },[search])
  useEffect(() => {
    const searchValue =currentSearch;
  
    const filteredChars = chars.filter((char) =>
      char.name ? char.name.toLowerCase().startsWith(searchValue.toLowerCase()) : false
    );
  
    const filteredParams = allParams.filter(
      (param) =>
        param.name.toLowerCase().startsWith(searchValue.toLowerCase()) &&
        !chars.some((char) => char.name === param.name)
    );
  
    setFilteredChars(filteredChars);
    setFilteredAllParams(filteredParams);
  }, [chars, allParams, currentSearch, language, search]);
  
const handleClick = (char, existingParam) => {
    setSelectedChar(char);
    if (char.type === "beneficial" || char.type === "beneficial[]") {
      setInsertNew(true);
      if(!existingParam){
        setExistingParam(false)
      }
    } else if (!existingParam) {
      setInsertNew(true);
      setExistingParam(false);
    } else {
      insertMention(char);
    }
  };
  

  const handleAdd = () => {
    setInsertNew(true);
    setSelectedChar(null);
  };
  return (
    <div style={{ position: "relative", paddingTop: "15px" }}>
      <span className="close-dialog" onClick={() => handleClose()}>
        X
      </span>
      {!insertNew ? (
        <>
          <div style={{ display:"block" , marginTop:"15px", position:"relative"}}>
            <input
              type="text"
              placeholder="..."
              className="dialog-search"
              value={currentSearch}
              onChange={(e) => setSearch(e.target.value)}
            />
            <BsSearch className="search-icon" style={{left:language=="ar"&&"16px"}} />
          </div>
          <div className="dialog-param-list">
            {filteredChars.length > 0 && filteredChars.map((char, i) => (
                            <div
                                key={char.name}
                                onClick={() => handleClick(char, true)}
                                style={{
                                    background: i === index ? '#eee3fd' : 'transparent',
                                    color: i === index ? '#2f14e5' : '#212529',
                                }}
                                className='dialog-element'
                            >
                                {char.name}
                            </div>
                        ))}
            {filteredAllParams.length > 0&&filteredChars.length>0 && (
              <hr style={{ margin: "10px 0", borderColor: "#ddd" }} />
            )}

            {filteredAllParams.length > 0 &&
              filteredAllParams.map((param, i) => (
                <div
                  key={param.name}
                  onClick={() => handleClick(param, false)}
                  className="dialog-element"
                >
                  {param.name}
                </div>
              ))}
          </div>
          <div className="dialog-add-element" onClick={() => handleAdd()}>
            {t(translationPath + ".add")}
          </div>
        </>
      ) : (
        <>
          <AddparamForm
            search={currentSearch}
            selected={selectedChar}
            existingParam={existingParam}
            params={chars}
            setInsertNew={setInsertNew}
            insertMention={insertMention}
            handleClose={handleClose}
          />
        </>
      )}
    </div>
  );
}

export default AddParamDialog;

import React, { useEffect, useState } from 'react'
import { ListParam, SegmentedClauseParam, SegmentedEnumParam } from '../../../../domain/types/ClauseParams';
import ParamTypeCombobox from '../../../../components/common/CustomCombobox/ParamTypeCombobox';
import { useTranslation } from '../../../../contexts/TranslationProvider';
import { BeneficialAttorneyEntity, BeneficialBaseEntity, BeneficialCompanyEntity, BeneficialEntity, BeneficialJointEntity, BeneficialMinorEntity, BeneficialPersonEntity } from '../../../../domain/entities';
import BeneficialFieldCombobox from '../../../../components/common/CustomCombobox/BeneficialFieldCombobox';
import BeneficialType from '../../../../components/BeneficialType';

function AddparamForm({ params, setInsertNew, insertMention, handleClose, selected, search, existingParam = true }) {
	type BeneficialField = {
		field: keyof BeneficialPersonEntity
		| keyof BeneficialCompanyEntity
		| keyof BeneficialMinorEntity
		| keyof BeneficialJointEntity
		| keyof BeneficialAttorneyEntity
		;
		label: string;
	};
	const { t } = useTranslation();
	const [selectedType, setSelectedType] = useState<'Person' | 'Company' | 'Minor' | 'Joint' | 'Attorney' | null>(null);
	const translationPath = "pages.contractTemplateParam.addParamForm";
	const beneficialTranslatioPath = "pages.editionContract.popups.benificial.inputs";
	const BeneficialListFields: BeneficialField[] = [
		{ field: 'description', label: t(beneficialTranslatioPath + ".company.description") },
		{ field: 'fullName', label: t(beneficialTranslatioPath + ".person.fullName") },
	];
	const personBeneficialFields: BeneficialField[] = [
		{ field: 'description', label: t(beneficialTranslatioPath + ".company.description") },
		{ field: 'firstName', label: t(beneficialTranslatioPath + ".person.firstname") },
		{ field: 'lastName', label: t(beneficialTranslatioPath + ".person.lastname") },
		{ field: 'fullName', label: t(beneficialTranslatioPath + ".person.fullName") },
		{ field: 'address', label: t(beneficialTranslatioPath + ".person.address") },
		{ field: 'cin', label: t(beneficialTranslatioPath + ".person.cin") },
		{ field: 'cinDeliverPlace', label: t(beneficialTranslatioPath + ".person.cinDeliverPlace") },
		{ field: 'cinDeliveryDate', label: t(beneficialTranslatioPath + ".person.cinDeliveryDate") },
		{ field: 'nationality', label: t(beneficialTranslatioPath + ".person.nationality") },
		{ field: 'dateOfBirth', label: t(beneficialTranslatioPath + ".person.dateOfBirth") },
		{ field: 'placeOfBirth', label: t(beneficialTranslatioPath + ".person.placeOfBirth") },
		{ field: 'maritalStatus', label: t(beneficialTranslatioPath + ".person.maritalStatus") },
		{ field: 'gender', label: t(beneficialTranslatioPath + ".person.gender") },
		{ field: 'jobTitle', label: t(beneficialTranslatioPath + ".person.jobTitle") },
		{ field: 'email', label: t(beneficialTranslatioPath + ".person.email") },
		{ field: 'passport', label: t(beneficialTranslatioPath + ".person.passport") }
	];

	const companyBeneficialFields: BeneficialField[] = [
		{ field: 'description', label: t(beneficialTranslatioPath + ".company.description") },
		{ field: 'socialCapital', label: t(beneficialTranslatioPath + ".company.socialCapital") },
		{ field: 'legalForm', label: t(beneficialTranslatioPath + ".company.legalForm") },
		{ field: 'companyName', label: t(beneficialTranslatioPath + ".company.companyName") },
		{ field: 'registrationNumber', label: t(beneficialTranslatioPath + ".company.registrationNumber") },
		{ field: 'socialCapitalCurrency', label: t(beneficialTranslatioPath + ".company.socialCapitalCurrency") },
		{ field: 'postalAddress', label: t(beneficialTranslatioPath + ".company.postalAddress") },
		{ field: 'specialization', label: t(beneficialTranslatioPath + ".company.specialization") },
		{ field: 'firstName', label: t(beneficialTranslatioPath + ".company.firstName") },
		{ field: 'lastName', label: t(beneficialTranslatioPath + ".company.lastName") },
		{ field: 'fullName', label: t(beneficialTranslatioPath + ".company.fullName") },
		{ field: 'gender', label: t(beneficialTranslatioPath + ".person.gender") },
		{ field: 'email', label: t(beneficialTranslatioPath + ".person.email") },
		{ field: 'cin', label: t(beneficialTranslatioPath + ".person.cin") },
		{ field: 'cinDeliverPlace', label: t(beneficialTranslatioPath + ".person.cinDeliverPlace") },
		{ field: 'cinDeliveryDate', label: t(beneficialTranslatioPath + ".person.cinDeliveryDate") },
		{ field: 'passport', label: t(beneficialTranslatioPath + ".person.passport") },
		{ field: 'nationality', label: t(beneficialTranslatioPath + ".company.nationality") }
	];

	const minorBeneficialFields: BeneficialField[] = [
		{ field: 'description', label: t(beneficialTranslatioPath + ".company.description") },
		{ field: 'minorFirstName', label: t(beneficialTranslatioPath + ".minor.minorFirstName") },
		{ field: 'minorLastName', label: t(beneficialTranslatioPath + ".minor.minorLastName") },
		{ field: 'minorFullName', label: t(beneficialTranslatioPath + ".minor.minorFullName") },
		{ field: 'minorDateOfBirth', label: t(beneficialTranslatioPath + ".minor.minorDateOfBirth") },
		{ field: 'minorPlaceOfBirth', label: t(beneficialTranslatioPath + ".minor.minorPlaceOfBirth") },
		{ field: 'minorNationality', label: t(beneficialTranslatioPath + ".minor.minorNationality") },
		{ field: 'minorGender', label: t(beneficialTranslatioPath + ".minor.minorGender") },
		{ field: 'minorOrderNumber', label: t(beneficialTranslatioPath + ".minor.minorOrderNumber") },
		{ field: 'minorOrderDate', label: t(beneficialTranslatioPath + ".minor.minorOrderDate") },
		{ field: 'minorOrderCourt', label: t(beneficialTranslatioPath + ".minor.minorOrderCourt") },
		{ field: 'firstName', label: t(beneficialTranslatioPath + ".minor.firstName") },
		{ field: 'lastName', label: t(beneficialTranslatioPath + ".minor.lastName") },
		{ field: 'fullName', label: t(beneficialTranslatioPath + ".minor.fullName") },
		{ field: 'gender', label: t(beneficialTranslatioPath + ".minor.gender") },
		{ field: 'cin', label: t(beneficialTranslatioPath + ".minor.cin") },
		{ field: 'cinDeliverPlace', label: t(beneficialTranslatioPath + ".minor.cinDeliverPlace") },
		{ field: 'cinDeliveryDate', label: t(beneficialTranslatioPath + ".minor.cinDeliveryDate") },
		{ field: 'placeOfBirth', label: t(beneficialTranslatioPath + ".minor.placeOfBirth") },
		{ field: 'dateOfBirth', label: t(beneficialTranslatioPath + ".minor.dateOfBirth") },
	];
	const jointBeneficialFields: BeneficialField[] = [
		{ field: 'description', label: t(beneficialTranslatioPath + ".company.description") },
		{ field: 'spouseFirstName', label: t(beneficialTranslatioPath + ".joint.spouseFirstName") },
		{ field: 'spouseLastName', label: t(beneficialTranslatioPath + ".joint.spouseLastName") },
		{ field: 'spouseFullName', label: t(beneficialTranslatioPath + ".joint.spouseFullName") },
		{ field: 'spouseGender', label: t(beneficialTranslatioPath + ".joint.spouseGender") },
		{ field: 'spouseCin', label: t(beneficialTranslatioPath + ".joint.spouseCin") },
		{ field: 'spouseCinDeliveryDate', label: t(beneficialTranslatioPath + ".joint.spouseCinDeliveryDate") },
		{ field: 'spouseCinDeliverPlace', label: t(beneficialTranslatioPath + ".joint.spouseCinDeliverPlace") },
		{ field: 'firstName', label: t(beneficialTranslatioPath + ".joint.firstName") },
		{ field: 'lastName', label: t(beneficialTranslatioPath + ".joint.lastName") },
		{ field: 'fullName', label: t(beneficialTranslatioPath + ".joint.fullName") },
		{ field: 'gender', label: t(beneficialTranslatioPath + ".joint.gender") },
		{ field: 'cin', label: t(beneficialTranslatioPath + ".joint.cin") },
		{ field: 'cinDeliverPlace', label: t(beneficialTranslatioPath + ".joint.cinDeliverPlace") },
		{ field: 'cinDeliveryDate', label: t(beneficialTranslatioPath + ".joint.cinDeliveryDate") },
		{ field: 'placeOfBirth', label: t(beneficialTranslatioPath + ".joint.placeOfBirth") },
		{ field: 'dateOfBirth', label: t(beneficialTranslatioPath + ".joint.dateOfBirth") },
	];
	const attorneyBeneficialFields: BeneficialField[] = [
		{ field: 'description', label: t(beneficialTranslatioPath + ".company.description") },
		{ field: 'attorneyFirstName', label: t(beneficialTranslatioPath + ".attorney.attorneyFirstName") },
		{ field: 'attorneyLastName', label: t(beneficialTranslatioPath + ".attorney.attorneyLastName") },
		{ field: 'attorneyFullName', label: t(beneficialTranslatioPath + ".attorney.attorneyFullName") },
		{ field: 'attorneyGender', label: t(beneficialTranslatioPath + ".attorney.attorneyGender") },
		{ field: 'attorneyCin', label: t(beneficialTranslatioPath + ".attorney.attorneyCin") },
		{ field: 'attorneyCinDeliveryDate', label: t(beneficialTranslatioPath + ".attorney.attorneyCinDeliveryDate") },
		{ field: 'attorneyCinDeliverPlace', label: t(beneficialTranslatioPath + ".attorney.attorneyCinDeliverPlace") },
		{ field: 'regionalFinancialOffice', label: t(beneficialTranslatioPath + ".attorney.regionalFinancialOffice") },
		{ field: 'powerOfAttorneyDate', label: t(beneficialTranslatioPath + ".attorney.powerOfAttorneyDate") },
		{ field: 'powerOfAttorneyReceitNumber', label: t(beneficialTranslatioPath + ".attorney.powerOfAttorneyReceitNumber") },
		{ field: 'powerOfAttorneyRegistrationNumber', label: t(beneficialTranslatioPath + ".attorney.powerOfAttorneyRegistrationNumber") },
		{ field: 'firstName', label: t(beneficialTranslatioPath + ".attorney.firstName") },
		{ field: 'lastName', label: t(beneficialTranslatioPath + ".attorney.lastName") },
		{ field: 'fullName', label: t(beneficialTranslatioPath + ".attorney.fullName") },
		{ field: 'gender', label: t(beneficialTranslatioPath + ".attorney.gender") },
		{ field: 'cin', label: t(beneficialTranslatioPath + ".attorney.cin") },
		{ field: 'cinDeliverPlace', label: t(beneficialTranslatioPath + ".attorney.cinDeliverPlace") },
		{ field: 'cinDeliveryDate', label: t(beneficialTranslatioPath + ".attorney.cinDeliveryDate") },
		{ field: 'placeOfBirth', label: t(beneficialTranslatioPath + ".attorney.placeOfBirth") },
		{ field: 'dateOfBirth', label: t(beneficialTranslatioPath + ".attorney.dateOfBirth") },
	];

	const [paramType, setParamType] = useState<SegmentedClauseParam['type'] | null>(selected?.type || null);
	const [paramName, setParamName] = useState<string | null>(selected?.name || search || "");
	const [paramLabel, setParamLabel] = useState<string | null>(selected?.label || "");
	const [paramField, setParamField] = useState<keyof BeneficialPersonEntity | keyof BeneficialCompanyEntity | keyof BeneficialMinorEntity | null>(selected?.args?.field || null);
	const [paramFieldOptions, setParamFieldOptions] = useState<BeneficialField[]>(personBeneficialFields);
	const [errors, setErrors] = useState([]);

	const onConfirm = () => {
		if (errors.length > 0 || !paramType || !paramName || !paramLabel || ((paramType === "beneficial" || paramType === "beneficial[]") && !paramField)) {
			return
		}
		let param: SegmentedClauseParam
		const defaultParam = {
			name: paramName, label: paramLabel,
			definition: 0
		}
		switch (paramType) {
			case 'enum':
				param = {
					...defaultParam, type: paramType,
					args: [],
				}
				break;
			case 'list':
				param = {
					name: paramName,
					label: paramLabel,
					type: paramType,
					args: [],
				}
				break;
			case 'boolean':
				param = {
					...defaultParam, type: paramType,
					args: {
						textIfTrue: [],
						textIfFalse: []
					}
				}
				break;
			case 'beneficial':
				param = {
					name: paramName,
					label: paramLabel,
					type: paramType,
					args: {
						beneficialTypes: [selectedType ? selectedType : "Person"],
					}
				}
				break;
			case 'beneficial[]':
				param = {
					...defaultParam,
					name: paramName,
					label: paramLabel,
					type: paramType,
					args: {
						beneficialTypes: ["Company", "Minor", "Person", "Joint", "Attorney"],
					}
				}
				break;
			case 'table':
				param = {
					name: paramName,
					label: paramLabel,
					type: paramType,
					transposed: false,
					args: []
				}
				break;
			case 'property':
				param = {
					name: paramName,
					label: paramLabel,
					type: paramType,
					args: { tag: "" }
				}
				break;
			default:
				param = { type: paramType, name: paramName, label: paramLabel }
				break;
		}
		if(selected){
			param = {
				...param,
				formula: selected.formula,
				constraints: selected.constraints,
			}
		}
		insertMention(param, paramField)
		setInsertNew(false)
		setParamType(null)
		setParamName("")
		setSelectedType(null)
		setParamLabel("")
		setParamField(null)
		handleClose()
	}

	const onCancel = () => {
		setInsertNew(false)
	}
	const beneficialFieldMap = {
		Person: personBeneficialFields,
		Company: companyBeneficialFields,
		Minor: minorBeneficialFields,
		Joint: jointBeneficialFields,
		Attorney: attorneyBeneficialFields,
	};

	useEffect(() => {
		if (paramType === "beneficial") {
			const beneficialType = selectedType || selected?.args?.beneficialTypes?.[0];

			if (beneficialType && beneficialFieldMap[beneficialType]) {
				setParamFieldOptions(beneficialFieldMap[beneficialType]);
			}
		}
		if (paramType == "beneficial[]") {
			setParamFieldOptions(BeneficialListFields);
		}
	}, [paramType, selected, selectedType]);
	useEffect(() => {
		if (selected && selected.name) {
			console.log(selected)
			const existingParam = params.find(param => param.name === selected.name);
			if (existingParam && existingParam.name !== selected.name) {
				setErrors(prev => [...prev, { where: "name", why: ".errors.nameExists" }]);
			}
			return;
		}
		const existingParam1 = params.find(param => param.name === search);
		if (existingParam1) {
			setErrors(prev => [...prev, { where: "name", why: ".errors.nameExists" }]);
		}
	}, [selected, search])

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrors(prev => prev.filter(err => err.where !== "name"));
		setParamName(e.target.value);
		if (!/^[a-z_][a-z0-9_]*$/i.test(e.target.value)) {
			setErrors(prev => [...prev, { where: "name", why: ".errors.nameWrong" }]);
		}
		const existingParam = params.find(param => param.name === e.target.value);
		if (!existingParam)
			return
		setErrors(prev => [...prev, { where: "name", why: ".errors.nameExists" }]);
	}

	const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrors(prev => prev.filter(err => err.where !== "label"));
		setParamLabel(e.target.value);
		if (!e.target.value) {
			setErrors(prev => [...prev, { where: "label", why: ".errors.labelMissing" }]);
		}
	}

	const handleTypeChange = (value) => {
		setErrors(prev => prev.filter(err => err.where !== "type"));
		setParamType(value);
		if (!value) {
			setErrors(prev => [...prev, { where: "type", why: ".errors.typeMissing" }]);
		}
	}

	const handleFieldChange = (value: keyof BeneficialPersonEntity | keyof BeneficialCompanyEntity | keyof BeneficialMinorEntity) => {
		if (value && (paramType === "beneficial" || paramType == "beneficial[]")) {
			setErrors(prev => prev.filter(err => err.where !== "field"));
		}
		setParamField(value);
		if (!value && (paramType === "beneficial" || paramType == "beneficial[]")) {
			setErrors(prev => [...prev, { where: "field", why: ".errors.fieldMissing" }]);
		}
	}
	return (
		<div
			className="dialog-container"
			style={{
				width: "100%",
				border: "none"
			}}>
			<div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".type")}
				</div>
				<ParamTypeCombobox
					options={['string', 'number', 'date', 'enum', 'boolean', 'list', 'file', 'beneficial', 'beneficial[]', 'table','property']}
					value={paramType}
					onChange={handleTypeChange}
					disabled={selected}
				/>
				{
					errors.map(err => {
						if (err.where === "type") {
							return <div className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>
			{(paramType == "beneficial") && (
				<div className="dialog-input-container">
					<div className="dialog-input-label">
						{t(translationPath + ".typeBeneficial")}
					</div>
					<BeneficialType
						selected={selected}
						disabled={selected}
						setSelectedType={setSelectedType}
					/>
				</div>)}

			<div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".name")}
				</div>
				<input disabled={selected} className="dialog-input" type="text" value={paramName} onChange={(e) => handleNameChange(e)} />
				{
					errors.map((err, idx) => {
						if (err.where === "name") {
							return <div key={idx} className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>

			<div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".label")}
				</div>
				<input disabled={!existingParam ? false : selected} className="dialog-input" type="text" value={paramLabel} onChange={(e) => handleLabelChange(e)} />
				{
					errors.map((err, idx) => {
						if (err.where === "label") {
							return <div key={idx} className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>

			{(paramType == "beneficial" || paramType == "beneficial[]") ? <div className="dialog-input-container">
				<div className="dialog-input-label">
					{t(translationPath + ".field")}
				</div>
				<BeneficialFieldCombobox
					options={paramFieldOptions}
					value={paramField}
					onChange={handleFieldChange}
				/>
				{
					errors.map((err, idx) => {
						if (err.where === "filed") {
							return <div key={idx} className="error-message-dialog">{t(translationPath + err.why)}</div>
						}
					})
				}
			</div>
				:
				<></>
			}
			<div className="dialog-buttons">
				<button className="dialog-button" onClick={onCancel}>
					{t(translationPath + ".cancel")}
				</button>
				<button
					className={`dialog-button ${errors.length > 0 || !paramType || !paramName || !paramLabel || ((paramType == "beneficial" || paramType == "beneficial[]") && !paramField) ? "dialog-button-disabled" : ""}`}
					onClick={onConfirm}>
					{t(translationPath + ".confirm")}
				</button>
			</div>

		</div>
	);
}

export default AddparamForm
/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useParams } from "react-router-dom";
import "./contractTemplatePage.scss";
import Loading from "../../components/common/Loading";
import { ClauseEntity, ContractEntity, ContractTemplateEntity, SubClauseEntity, InitialContractTemplateEntity, GroupEntity } from "../../domain/entities";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { LoadingContext } from "../../contexts/LoadingContext";
import EditContractTemplate from "./components/EditContractTemplate";
import EditContractTemplateContext, { EditContractTemplateContextType } from "../../contexts/EditContractTemplateContext";
import { EnumOption, ListOption, TableLigne, SegmentedClauseParam, SegmentedTextType, SegmentedEnumParam, ListParam, TableParam, SegmentedBooleanParam, SegmentedText, SegmentedBeneficialParam, SegmentedBeneficialListParam, RenderSegments } from "../../domain/types/ClauseParams";
import { updateSegment, insertParamInSegment, genIdFactory, cleanSegmentation, deleteSegment, fixTemplateIndexation, idfySegmentation, styleSegmentation } from "../../domain/ContractTemplate";
import { ContractTemplateClient } from "../../services/api/ContractTemplateClient";
import { InitialContractTemplateClient } from "../../services/api/InitialContractTemplateClient"
import { useTranslation } from "../../contexts/TranslationProvider";
import EventManager from "../../services/EventManager";
import { toast } from "react-toastify";
import { normalizeName } from "../../utils/string";
import { GroupClient } from "../../services/api/GroupClient";
import { removeDuplicates } from "../../utils/array";


function ContractTemplatePage() {
	const location = useLocation();
	const { isLoading, setLoading } = useContext(LoadingContext);
	const { setLanguage, language, t } = useTranslation();
	const translationPath = "pages.contractTemplateParam."


	const [contractTemplate, setContractTemplate] = useState<ContractTemplateEntity>(null);
	const [initialTemplate, setInitialTemplate] = useState<InitialContractTemplateEntity>(null);
	const [combinedTemplateParams, setCombinedTemplateParams] = useState<SegmentedClauseParam[]>([]);
	const [groups, setGroups] = useState<GroupEntity[]>([]);
	const [previewValues, setPreviewValues] = useState<ContractEntity['paramValues']>({});

	const { contractTemplateId } = useParams<{ contractTemplateId?: string }>() || {};
	const apiClient = useApiClientWithLoading(setLoading);
	const contractTemplateClient = new ContractTemplateClient(apiClient)
	const initialContractTemplateClient = new InitialContractTemplateClient(apiClient)
	const groupClient = new GroupClient(apiClient)
	const setTemplateGroups = (group: GroupEntity, add: boolean) => {
		let newGroups = [...contractTemplate.groups];
		if (add) {
			newGroups = [...contractTemplate.groups, {
				...group,
				Group_ContractTemplate: {
					clauseCodes: contractTemplate.clauses.map(clause => clause.index)
				}
			}];
		} else {
			newGroups = newGroups.filter(g => g.id !== group.id);
		}
		const newContractTemplate = { ...contractTemplate };
		newContractTemplate.groups = newGroups;
		setContractTemplate(newContractTemplate);
	}
	const onSelectGroupInClause = async (index: string, newGroups: GroupEntity[]) => {
		console.log(newGroups)
		const templateGroups = contractTemplate.groups;
		templateGroups.forEach(group => {
			if (newGroups.some(g => g.id === group.id)) {
				if (!group.Group_ContractTemplate.clauseCodes.includes(index)) {
					group.Group_ContractTemplate.clauseCodes.push(index);
				}
			} else {
				group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.filter(code => code !== index);
			}
		})
		const updatedContractTemplate = { ...contractTemplate };
		updatedContractTemplate.groups = templateGroups;
		setContractTemplate(updatedContractTemplate);
	};

	const prepareTemplate = (template: ContractTemplateEntity) => {
		const clauses = template.clauses?.map(clause => {
			const newClause = { ...clause }
			newClause.segmentation.segmentedParams = newClause.segmentation.segmentedParams ?? []
			if (newClause.segmentation.segmentedText.length == 0) {
				newClause.segmentation.segmentedText = [["", "_", SegmentedTextType.STATIC]]
			}
			newClause.segmentation = idfySegmentation(newClause.segmentation, genIdFactory(clause.index))
			newClause.subClauses = clause.subClauses?.map(subClause => {
				const newSubClause = { ...subClause }
				newSubClause.segmentation.segmentedParams = newSubClause.segmentation.segmentedParams ?? []
				if (newSubClause.segmentation.segmentedText.length == 0) {
					newSubClause.segmentation.segmentedText = [["", "_", SegmentedTextType.STATIC]]
				}
				newSubClause.segmentation = idfySegmentation(newSubClause.segmentation, genIdFactory(subClause.index))
				return newSubClause
			})
			return newClause
		})
		return { ...template, clauses }
	}
	useEffect(() => {
		(async () => {
			const row = await contractTemplateClient.getById(parseInt(contractTemplateId));
			if (!row) return;
			if (row.languages) {
				const { languages } = row;
				if (languages?.length !== 0 && (!(languages as string[]).includes(language))) {
					setLanguage(languages[0]);
				}
			}
			const groupData = await groupClient.getAll();
			setGroups(groupData.rows);
			setContractTemplate(prepareTemplate(row));
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const result = await initialContractTemplateClient.getOriginal(parseInt(contractTemplateId));
				if (!result) return;
				setInitialTemplate((result as any).row);
			} catch (e) {
				console.error(e)
			}
		})();
	}, []);

	useEffect(() => {
		if (!contractTemplate) return;
		// combine params in contractTemplate
		const combinedParams = contractTemplate.clauses.reduce((acc, clause) => {
			const clauseParams = clause.segmentation.segmentedParams
			acc.push(...clauseParams);

			clause.subClauses?.forEach(subClause => {
				const subClauseParams = subClause.segmentation.segmentedParams
				acc.push(...subClauseParams);
			});

			return acc;
		}, [] as SegmentedClauseParam[]);

		// Filter out duplicate parameters based on the 'name' property
		const uniqueParams = combinedParams.reduce((acc, param) => {
			if (!acc.some(p => p.name === param.name)) {
				acc.push(param);
			}
			return acc;
		}, [] as SegmentedClauseParam[]);

		setCombinedTemplateParams(uniqueParams);

	}, [contractTemplate]);

	const updateInitialTemplate = async () => {
		try {
			const result = await initialContractTemplateClient.getOriginal(parseInt(contractTemplateId));
			if (!result) return;
			setInitialTemplate((result as any).row);
		} catch (e) {
			console.error(e)
		}
	}

	const insertClauseInContractTemplate = async (name) => {
		const result = await contractTemplateClient.createClause(parseInt(contractTemplateId), name);
		if (!result.row) return;
		return result.row
	}

	const insertExistingClauseInContractTemplate = async (clauseId: ClauseEntity['id'], newName: string) => {
		const result = await contractTemplateClient.addExistingClause(parseInt(contractTemplateId), clauseId, newName);
		if (!result.row) return;
		return result.row
	}

	const insertSubClauseInContractTemplate = async (clauseId: ClauseEntity['id'], name: string) => {
		const result = await contractTemplateClient.createSubClause(clauseId, name);
		if (!result.row) return;
		return result.row
	}

	const fixClauseIndexation = (clause: ClauseEntity) => {
		const newSubClauses = clause.subClauses?.map((subClause, index) => {
			subClause.index = (index + 1).toString()

			subClause.segmentation = idfySegmentation(subClause.segmentation, genIdFactory(subClause.index))
			return subClause
		})
		return { ...clause, subClauses: newSubClauses }
	}

	const findSegmentationExistingParamName = (segmentation: ClauseEntity['segmentation'], name: string) => {
		const existingParam = segmentation.segmentedParams.find(p => p.name === name);
		if (existingParam) {
			return existingParam
		}
		return null
	}
	const findExistingParamName = (name: string) => {
		contractTemplate.clauses.forEach(clause => {
			const existingParam = findSegmentationExistingParamName(clause.segmentation, name)
			if (existingParam)
				return existingParam
			clause.subClauses.forEach(subClause => {
				const existingParam = findSegmentationExistingParamName(subClause.segmentation, name)
				if (existingParam)
					return existingParam
			})
		})
		return null

	}
	const isParamSegment = (segment: SegmentedText[number], param: SegmentedClauseParam) => {
		const [id, value, type] = segment

		return type == SegmentedTextType.PARAM && (value == param.name || value.startsWith(`${param.name}.`))
	}
	const onSegmentationParamNameChanged = (segmentation: ClauseEntity['segmentation'], param: SegmentedClauseParam, newValue: string) => {
		segmentation.segmentedText = segmentation.segmentedText.map((segment) => {
			const [id, value, type] = segment
			if (isParamSegment(segment, param)) {
				return [id, value.replace(param.name, newValue), type]
			}
			return segment
		})

		segmentation.segmentedParams = segmentation.segmentedParams.map((segParam) => {
			if (segParam.name == param.name) {
				return {
					...segParam,
					name: newValue,
				}
			}
			return segParam
		})
		return segmentation
	}
	const onParamNameChanged = (
		param: SegmentedClauseParam,
		newValue: string
	) => {
		const existingParam = findExistingParamName(newValue)
		if (existingParam) {
			console.log("oups");
			return
		}
		let newContractTemplateValue = { ...contractTemplate }
		// update all params with the same name and type
		newContractTemplateValue.clauses.forEach(clause => {
			clause.segmentation = onSegmentationParamNameChanged(clause.segmentation, param, newValue)
			clause.subClauses.forEach(subClause => {
				subClause.segmentation = onSegmentationParamNameChanged(subClause.segmentation, param, newValue)
				return subClause
			})
		})

		setContractTemplate(newContractTemplateValue)
	}
	const onSegmentaionParamChanged = (segmentation: ClauseEntity['segmentation'], param: SegmentedClauseParam) => {
		switch (param.type) {
			case 'beneficial':
			case 'beneficial[]':
			case 'comment':
			case 'csv':
			case 'date':
			case 'list':
			case 'table':
			case 'property':
			case 'number':
				segmentation.segmentedParams = segmentation.segmentedParams.map((segParam) => {
					if (segParam.name == param.name) {
						return {
							...param,
						}
					}
					return segParam
				})
				break
			case 'enum':
			case 'boolean':
				segmentation.segmentedParams = segmentation.segmentedParams.map((segParam) => {
					if (segParam.name == param.name) {
						return {
							...segParam,
							label: param.label,
						}
					}
					return segParam
				})
				break;

			default:
				break;
		}
		return segmentation
	}
	const onParamChanged: EditContractTemplateContextType['onParamChanged'] = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: SegmentedClauseParam) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let segmentation: ClauseEntity['segmentation']

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		switch (param.type) {
			case 'enum':
			case 'boolean':
				segmentation.segmentedParams = segmentation.segmentedParams.map((segParam) => {
					if (segParam.name == param.name && param.definition == (segParam as any).definition) {
						return {
							...param,
						}
					}
					return segParam
				})
				break;
			default:
				segmentation.segmentedParams = segmentation.segmentedParams.map((segParam) => {
					if (segParam.name == param.name) {
						return {
							...param,
						}
					}
					return segParam
				}
				)
				break;
		}

		if (subClauseId) {
			segmentation = idfySegmentation(segmentation, genIdFactory(subClause.index))
			subClause.segmentation = segmentation
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			segmentation = idfySegmentation(segmentation, genIdFactory(clause.index))
			clause.segmentation = segmentation
		}
		let newContractTemplateValue = { ...contractTemplate }
		// update all params with the same name and type
		newContractTemplateValue.clauses.forEach(clause => {
			clause.segmentation = onSegmentaionParamChanged(clause.segmentation, param)
			clause.subClauses.forEach(subClause => {
				subClause.segmentation = onSegmentaionParamChanged(subClause.segmentation, param)
				return subClause
			})
		})

		newContractTemplateValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTemplateValue)
	}

	const onApplySegmentation: EditContractTemplateContextType['onApplySegmentation'] = async (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let segmentation: ClauseEntity['segmentation']
		console.log("styledSegments: ", styledSegments)
		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		const styledSegmentation = styleSegmentation(segmentation, styledSegments, deletedSegments)
		const newSegmentation = idfySegmentation(styledSegmentation, genIdFactory(subClauseId ? subClause.index : clause.index))
		if (subClauseId) {
			subClause.segmentation = newSegmentation
			clause.subClauses[subClauseIdx] = subClause
			clause.subClauses = [...clause.subClauses]
		} else {
			clause.segmentation = newSegmentation
		}
		const newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTemplateValue)
	}

	const onParamDelete: EditContractTemplateContextType['onParamDelete'] = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: SegmentedClauseParam) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let segmentation: ClauseEntity['segmentation']
		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		segmentation.segmentedParams = segmentation.segmentedParams.filter(segP => segP.name !== param.name)
		segmentation.segmentedText = segmentation.segmentedText.map(segment => {
			if (isParamSegment(segment, param))
				return [segment[0], segment[1], SegmentedTextType.STATIC]
			return segment
		})
		if (subClauseId) {
			segmentation = idfySegmentation(segmentation, genIdFactory(subClause.index))
			subClause.segmentation = segmentation
			clause.subClauses = [...clause.subClauses]
		} else {
			segmentation = idfySegmentation(segmentation, genIdFactory(clause.index))
			clause.segmentation = segmentation
		}
		let newContractTemplateValue = { ...contractTemplate }
		console.log(segmentation);


		setContractTemplate(newContractTemplateValue);
	}
	const onSegmentaionParamTypeChanged = (segmentation: ClauseEntity['segmentation'], param: SegmentedClauseParam, type: SegmentedClauseParam['type']) => {
		let definition = 0
		segmentation.segmentedParams = segmentation.segmentedParams.map((segP) => {
			if (segP.name == param.name) {
				switch (type) {
					case "boolean":
						return {
							...param,
							type: 'boolean',
							definition: definition++,
							args: {
								textIfTrue: [["", "_", SegmentedTextType.STATIC]],
								textIfFalse: [["", "_", SegmentedTextType.STATIC]],
							}
						} as SegmentedClauseParam
					case "enum":
						return {
							...param,
							type: 'enum',
							definition: definition++,
							args: []
						} as SegmentedClauseParam
					case "beneficial":
						return {
							...param,
							type: 'beneficial',
							args: {
								beneficialTypes: []
							}
						} as SegmentedClauseParam
					case "beneficial[]":
						return {
							...param,
							type: 'beneficial[]',
							args: {
								beneficialTypes: ['Company', 'Minor', 'Person', 'Joint', 'Attorney'],
							}
						} as SegmentedClauseParam
					case "list":
					case "table":
						return {
							...param,
							type,
							args: []
						} as SegmentedClauseParam
					case "property":
						return {
							...param,
							type,
							args: { tag: "" }
						} as SegmentedClauseParam
					case "number":
					case "date":
					case "comment":
					case "csv":
					case "file":
					case "string":
						return {
							...param,
							type,
						} as SegmentedClauseParam
					default:
						return segP
				}
			}
			return segP
		})

		return segmentation
	}
	const onParamTypeChange: EditContractTemplateContextType['onParamTypeChange'] = (param: SegmentedClauseParam, type: SegmentedClauseParam['type']) => {
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses.forEach(clause => {
			clause.segmentation = onSegmentaionParamTypeChanged(clause.segmentation, param, type)
			clause.subClauses.map(subClause => {
				subClause.segmentation = onSegmentaionParamTypeChanged(subClause.segmentation, param, type)
				return subClause
			})
		})
		setContractTemplate(newContractTemplateValue)
	}
	const onSegmentationOptionDelete = (segmentation: ClauseEntity['segmentation'], param: SegmentedClauseParam, index: number) => {
		segmentation.segmentedParams = segmentation.segmentedParams.map((segP) => {
			if (segP.name == param.name) {
				switch (param.type) {
					case "enum":
					case "list":
					case "table":
						return {
							...param,
							args: param.args.filter((arg, idx) => idx != index)
						} as SegmentedClauseParam
					default:
						return segP
				}
			}
			return segP
		})

		return segmentation
	}
	const onOptionDelete: EditContractTemplateContextType['onOptionDelete'] = (param: SegmentedClauseParam, index: number) => {
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses.forEach(clause => {
			clause.segmentation = onSegmentationOptionDelete(clause.segmentation, param, index)
			clause.segmentation = idfySegmentation(clause.segmentation, genIdFactory(clause.index))
			clause.subClauses.map(subClause => {
				subClause.segmentation = onSegmentationOptionDelete(subClause.segmentation, param, index)
				subClause.segmentation = idfySegmentation(subClause.segmentation, genIdFactory(subClause.index))
				return subClause
			})
		})
		setContractTemplate(newContractTemplateValue)
	}

	const onSegmentationOptionChanged = (segmentation: ClauseEntity['segmentation'], param: SegmentedEnumParam | ListParam,
		option: SegmentedEnumParam['args'][number]['option'] | ListParam['args'][number], index: number) => {
		segmentation.segmentedParams = segmentation.segmentedParams.map((segP) => {
			if (segP.name == param.name) {
				switch (param.type) {
					case "enum":
						return {
							...param,
							args: param.args.map((arg, idx) => idx == index ? {
								text: arg.text,
								option: option
							} : arg)
						} as SegmentedEnumParam
					case "list":
						return {
							...param,
							args: param.args.map((arg, idx) => idx == index ? option : arg)
						} as ListParam
					default:
						return segP
				}
			}
			return segP
		})

		return segmentation
	}
	const onOptionChanged: EditContractTemplateContextType['onOptionChanged'] = (param: SegmentedEnumParam | ListParam,
		option: SegmentedEnumParam['args'][number]['option'] | ListParam['args'][number], index: number) => {
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses.forEach(clause => {
			clause.segmentation = onSegmentationOptionChanged(clause.segmentation, param, option, index)
			clause.segmentation = idfySegmentation(clause.segmentation, genIdFactory(clause.index))
			clause.subClauses.map(subClause => {
				subClause.segmentation = onSegmentationOptionChanged(subClause.segmentation, param, option, index)
				subClause.segmentation = idfySegmentation(subClause.segmentation, genIdFactory(subClause.index))
				return subClause
			})
		})
		setContractTemplate(newContractTemplateValue)
	}
	const onSegmentationOptionAdd = (segmentation: ClauseEntity['segmentation'],
		param: SegmentedEnumParam | ListParam | TableParam | SegmentedBooleanParam,
		option: EnumOption | ListOption | TableLigne | boolean) => {
		segmentation.segmentedParams = segmentation.segmentedParams.map((segP) => {
			if (segP.name == param.name) {
				switch (param.type) {
					case "enum":
						param.args.push({
							text: [["", "_", SegmentedTextType.STATIC]],
							option: (option as EnumOption).option
						})
						return param
					case "list":
						param.args.push(option as ListOption)
						return param
					case "table":
						param.args.push(option as TableLigne)
						return param
					case "boolean":
						if (option) {
							param.args.textIfTrue = [["", "_", SegmentedTextType.STATIC]]
						} else {
							param.args.textIfFalse = [["", "_", SegmentedTextType.STATIC]]
						}
						return param
					default:
						return segP
				}
			}
			return segP
		})
		return segmentation
	}
	const onOptionAdd: EditContractTemplateContextType['onOptionAdd'] = (
		param: SegmentedEnumParam | ListParam | TableParam | SegmentedBooleanParam,
		option: EnumOption | ListOption | TableLigne | boolean
	) => {
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses.forEach(clause => {
			clause.segmentation = onSegmentationOptionAdd(clause.segmentation, param, option)
			clause.segmentation = idfySegmentation(clause.segmentation, genIdFactory(clause.index))
			clause.subClauses.map(subClause => {
				subClause.segmentation = onSegmentationOptionAdd(subClause.segmentation, param, option)
				subClause.segmentation = idfySegmentation(subClause.segmentation, genIdFactory(subClause.index))
				return subClause
			})
		})
		setContractTemplate(newContractTemplateValue)
	};

	const onSegmentChange: EditContractTemplateContextType['onSegmentChange'] = (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string, segmentText: string) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let segmentation: ClauseEntity['segmentation']
		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		segmentation = updateSegment(segmentation.segmentedText, segmentation.segmentedParams, id, segmentText)
		segmentation = idfySegmentation(segmentation, genIdFactory(subClause?.index || clause.index))

		if (subClauseId) {
			segmentation = idfySegmentation(segmentation, genIdFactory(subClause.index))
			subClause.segmentation = segmentation
			clause.subClauses = [...clause.subClauses]
		} else {
			segmentation = idfySegmentation(segmentation, genIdFactory(clause.index))
			clause.segmentation = segmentation
		}
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTemplateValue)

	}

	const onAddParam: EditContractTemplateContextType['onAddParam'] = (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string, newParam: SegmentedClauseParam, textBefore: string, textAfter: string, segments: ClauseEntity['segmentation']['segmentedText'], field: string, deletedSegments: RenderSegments) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let segmentation: ClauseEntity['segmentation']
		let clauseIdx: number
		let subClauseIdx: number

		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if ((newParam.type == "beneficial" || newParam.type == "beneficial[]")) {
			if (combinedTemplateParams.find(p => p.name === newParam.name && p.type === newParam.type)) {
				newParam.args = (combinedTemplateParams.find(p => p.name === newParam.name && p.type === newParam.type) as any).args
			}
		}

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		const existingParam = segmentation.segmentedParams.find(p => p.name === newParam.name && p.type === newParam.type)
		let newSegmentation
		if (!existingParam) {
			newSegmentation = insertParamInSegment(segmentation.segmentedText, segmentation.segmentedParams, id, newParam, 0, textBefore, textAfter, field, segments, deletedSegments)
		} else {
			const definitions = segmentation.segmentedParams.filter((param) => param.name == newParam.name && param.type == newParam.type)
			const definition = definitions.reduce((prev, curr) => Math.max(prev, (curr as any).definition || 0), 0) + 1
			newSegmentation = insertParamInSegment(segmentation.segmentedText, segmentation.segmentedParams, id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments)
		}
		console.log("newSegmentation: ", newSegmentation)
		if (subClauseId) {
			segmentation = idfySegmentation(newSegmentation, genIdFactory(subClause.index))
			subClause.segmentation = segmentation
			clause.subClauses = [...clause.subClauses]
		} else {
			segmentation = idfySegmentation(newSegmentation, genIdFactory(clause.index))
			clause.segmentation = segmentation
		}

		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTemplateValue)
	}

	const onParamReorder: EditContractTemplateContextType['onParamReorder'] = (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], oldIndex: number, newIndex: number) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let segmentation: ClauseEntity['segmentation']
		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		const uniqueParams = removeDuplicates(segmentation.segmentedParams, "name")
		const duplicateParams = segmentation.segmentedParams.filter((param, index) => {
			return uniqueParams.findIndex(p => p.name === param.name) !== index
		})
		const [removed] = uniqueParams.splice(oldIndex, 1)
		uniqueParams.splice(newIndex, 0, removed)
		const newParams = uniqueParams.concat(duplicateParams)
		segmentation.segmentedParams = newParams
		if (subClauseId) {
			segmentation = idfySegmentation(segmentation, genIdFactory(subClause.index))
			subClause.segmentation = segmentation
			clause.subClauses = [...clause.subClauses]
		} else {
			segmentation = idfySegmentation(segmentation, genIdFactory(clause.index))
			clause.segmentation = segmentation
		}
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTemplateValue)
	}

	const onClauseDelete: EditContractTemplateContextType['onClauseDelete'] = async (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], applyMerge: Boolean): Promise<ContractTemplateEntity> => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
		}

		let newContractTemplateValue = { ...contractTemplate }
		clause = { ...clause }
		if (subClause) {
			if (applyMerge) {
				if (subClauseIdx === 0) {
					clause.segmentation.segmentedText = [
						...clause.segmentation.segmentedText,
						["", `\n${subClause.name}\n`, SegmentedTextType.STATIC],
						...subClause.segmentation.segmentedText,
					]
					clause.segmentation.segmentedParams = [
						...clause.segmentation.segmentedParams,
						...subClause.segmentation.segmentedParams,
					]
					clause.segmentation = idfySegmentation(clause.segmentation, genIdFactory(clause.index))
					console.log(clause.segmentation);
				} else {
					let prevSubClause = clause.subClauses[subClauseIdx - 1];
					prevSubClause.segmentation.segmentedText = [
						...prevSubClause.segmentation.segmentedText,
						["", `\n${subClause.name}\n`, SegmentedTextType.STATIC],
						...subClause.segmentation.segmentedText,
					]
					prevSubClause.segmentation.segmentedParams = [
						...prevSubClause.segmentation.segmentedParams,
						...subClause.segmentation.segmentedParams,
					]
					prevSubClause.segmentation = idfySegmentation(prevSubClause.segmentation, genIdFactory(prevSubClause.index))
					console.log(prevSubClause.segmentation);

					clause.subClauses[subClauseIdx - 1] = prevSubClause;
				}
			}
			clause.subClauses = clause.subClauses.filter(sc => sc.id !== subClauseId);
			newContractTemplateValue.clauses[clauseIdx] = clause;
		} else {
			const result = await contractTemplateClient.deleteClause(clauseId);
			if (result) {
				newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => c.id !== clauseId);
				toast.success(t(translationPath + "toasts.deleteClause.success"))
			}
		}
		setContractTemplate(newContractTemplateValue);
		return newContractTemplateValue

	}
	const onClauseSwap: EditContractTemplateContextType['onClauseSwap'] = async (index: number, localId: number, externalId: number, externalName: string): Promise<ContractTemplateEntity> => {
		const newContractTemplateValue = { ...contractTemplate }
		const result = await contractTemplateClient.deleteClause(localId);
		if (result) {
			newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => c.id !== localId);
			toast.success(t(translationPath + "toasts.deleteClause.success"))
		}
		const row = await insertExistingClauseInContractTemplate(externalId, externalName)
		if (!row.id || !row.code) {
			return contractTemplate
		}
		const newClause: ClauseEntity = {
			...row,
			index: index.toString(),
		}
		newClause.segmentation = idfySegmentation(newClause.segmentation, genIdFactory(newClause.index))
		// remove clause if duplicate
		newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => normalizeName(c.name) !== normalizeName(newClause.name))
		// reinsert new clause
		newContractTemplateValue.clauses.splice(index, 0, newClause)
		const newContractTemplate = fixTemplateIndexation(newContractTemplateValue)
		// save template
		setContractTemplate(newContractTemplate)
		return newContractTemplate
	}

	const onSegmentDelete: EditContractTemplateContextType['onSegmentDelete'] = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let segmentation: ClauseEntity['segmentation']
		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}
		console.log("segmentation: ", segmentation.segmentedText)
		console.log("styledSegments: ", styledSegments)
		console.log("deletedSegments: ", deletedSegments)
		segmentation = deleteSegment(segmentation.segmentedText, segmentation.segmentedParams, id, styledSegments, deletedSegments)
		console.log("segmenttation after deletion: ", segmentation)

		if (subClauseId) {
			segmentation = idfySegmentation(segmentation, genIdFactory(subClause.index))
			subClause.segmentation = segmentation
			clause.subClauses = [...clause.subClauses]
		} else {
			segmentation = idfySegmentation(segmentation, genIdFactory(clause.index))
			clause.segmentation = segmentation
		}
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses[clauseIdx] = clause
		setContractTemplate(newContractTemplateValue)
	}

	const insertClause: EditContractTemplateContextType['insertClause'] = async (insertIndex: number, clauseName: string): Promise<ContractTemplateEntity> => {
		const row = await insertClauseInContractTemplate(clauseName)
		if (!row.id || !row.code) return;
		const newClause: ClauseEntity = {
			...row,
			name: clauseName,
			index: insertIndex.toString(),
			subClauses: [],
		}
		const newContractTemplateValue = { ...contractTemplate }
		// reinsert new clause
		newContractTemplateValue.clauses.splice(insertIndex, 0, newClause)
		const newContractTemplate = fixTemplateIndexation(newContractTemplateValue)
		setContractTemplate(newContractTemplate)
		return newContractTemplate
	}

	const insertImportedClause: EditContractTemplateContextType['insertImportedClause'] = async (insertIndex: number, clauseId: ClauseEntity['id'], newName: string): Promise<ContractTemplateEntity> => {
		const row = await insertExistingClauseInContractTemplate(clauseId, newName)
		if (!row.id || !row.code) {
			return contractTemplate
		}
		const newClause: ClauseEntity = {
			...row,
			index: insertIndex.toString(),
		}
		newClause.segmentation = idfySegmentation(newClause.segmentation, genIdFactory(newClause.index))

		const newContractTemplateValue = { ...contractTemplate }
		// remove clause if duplicate
		newContractTemplateValue.clauses = newContractTemplateValue.clauses.filter(c => c.name !== newClause.name)
		// reinsert new clause
		newContractTemplateValue.clauses.splice(insertIndex, 0, newClause)
		const newContractTemplate = fixTemplateIndexation(newContractTemplateValue)
		// save template
		setContractTemplate(newContractTemplate)
		//EventManager.invoke('SaveContractTemplate', true)
		return contractTemplate
	}

	const onClauseNameChange: EditContractTemplateContextType['onClauseNameChange'] = (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], name: string) => {
		let clause: ClauseEntity
		let subClause: SubClauseEntity
		let clauseIdx: number
		let subClauseIdx: number
		let segmentation: ClauseEntity['segmentation']
		clauseIdx = contractTemplate.clauses!.findIndex(c => c.id == clauseId)
		clause = contractTemplate.clauses[clauseIdx]

		if (subClauseId) {
			subClauseIdx = clause.subClauses!.findIndex(sc => sc.id == subClauseId)
			subClause = clause.subClauses[subClauseIdx]
			segmentation = subClause.segmentation
		} else {
			segmentation = clause.segmentation
		}

		if (subClause) {
			subClause.name = name;
			clause.subClauses[subClauseIdx] = subClause;
		} else {
			clause.name = name;
		}
		let newContractTemplateValue = { ...contractTemplate }
		newContractTemplateValue.clauses[clauseIdx] = clause;
		setContractTemplate(newContractTemplateValue);
	}

	const insertSubClause: EditContractTemplateContextType['insertSubClause'] = async (insertIndex: number, clauseId: ClauseEntity['id'], subClauseName: string): Promise<ContractTemplateEntity> => {
		const result = await insertSubClauseInContractTemplate(clauseId, subClauseName)
		if (!result.id || !result.code) return;
		const newContractTemplateValue = { ...contractTemplate };
		const newSubClause: SubClauseEntity = {
			...result,
			name: subClauseName,
			index: insertIndex.toString(),
		}
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);
		if (clauseIdx == -1) return;
		const clause = { ...newContractTemplateValue.clauses[clauseIdx] };
		clause.subClauses.splice(insertIndex, 0, newSubClause);
		const newClause = fixClauseIndexation(clause)
		newContractTemplateValue.clauses[clauseIdx] = newClause;
		setContractTemplate(newContractTemplateValue);
		return newContractTemplateValue
	}

	const insertSubClauseWithContent: EditContractTemplateContextType['insertSubClauseWithContent'] = async (insertIndex: number, clauseId: ClauseEntity['id'], subClause: SubClauseEntity): Promise<ContractTemplateEntity> => {
		const result = await insertSubClauseInContractTemplate(clauseId, subClause.name)
		if (!result.id || !result.code) return;
		const newContractTemplateValue = { ...contractTemplate };
		const newSubClause: SubClauseEntity = {
			...result,
			name: subClause.name,
			index: insertIndex.toString(),
			segmentation: idfySegmentation(subClause.segmentation, genIdFactory(insertIndex.toString()))
		}
		const clauseIdx = newContractTemplateValue.clauses!.findIndex(c => c.id === clauseId);
		if (clauseIdx == -1) return;
		const clause = { ...newContractTemplateValue.clauses[clauseIdx] };
		clause.subClauses.splice(insertIndex, 0, newSubClause);
		const newClause = fixClauseIndexation(clause)
		newContractTemplateValue.clauses[clauseIdx] = newClause;
		setContractTemplate(newContractTemplateValue);
		return newContractTemplateValue
	}

	const contractListParentRef = useRef()
	const EditContractTemplateContextValue: EditContractTemplateContextType = useMemo(() => {
		return {
			contractTemplate,
			setContractTemplate,
			combinedTemplateParams,
			setCombinedTemplateParams,
			previewValues,
			setPreviewValues,
			onParamChanged,
			contractListParentRef,
			onParamDelete,
			onOptionAdd,
			onSegmentChange,
			onAddParam,
			onOptionChanged,
			onOptionDelete,
			onParamTypeChange,
			onParamReorder,
			onClauseDelete,
			onSegmentDelete,
			insertClause,
			onClauseNameChange,
			insertSubClause,
			onParamNameChanged,
			initialTemplate,
			setInitialTemplate,
			updateInitialTemplate,
			insertImportedClause,
			insertSubClauseWithContent,
			onClauseSwap,
			onSelectGroupInClause,
			groups,
			setTemplateGroups,
			onApplySegmentation,
		}

	}, [contractTemplate, combinedTemplateParams, previewValues, contractListParentRef, initialTemplate, onParamNameChanged, setTemplateGroups])
	useEffect(() => {
		EventManager.invoke("EditContractTemplateContext", EditContractTemplateContextValue)
		return () => {
		}
	}, [EditContractTemplateContextValue])

	return (
		<EditContractTemplateContext.Provider value={EditContractTemplateContextValue}>
			<div className="contract-container d-flex justify-content-between align-items-center">
				{!contractTemplate ? (
					<div className="contract-loading-container">
						<Loading height="90vh" />
					</div>
				) : (
					<EditContractTemplate />
				)}
			</div>
		</EditContractTemplateContext.Provider>
	);
}

export default ContractTemplatePage;

import { ClauseEntity, ContractTemplateEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "./entities";
import { RenderSegments, SegmentedClauseParam, SegmentedClauseParams, SegmentedEnumParam, SegmentedText, SegmentedTextType } from "./types/ClauseParams";
import _ from 'lodash'

var counter = 0
export const genIdFactory = (code: string) => {
	counter = 0
	return () => `${code}-${++counter}`
}

export function updateParamNameInText(text: string, oldName: string, newName: string) {
	return text.replace(new RegExp(`\\$${oldName}(\\b|\\.)`, 'g'), `$${newName}$1`);
}

export function getAllParams(template: ContractTemplateEntity) {
	const params: SegmentedClauseParams = []
	template?.clauses?.forEach((clause) => {
		params.push(...(clause.segmentation.segmentedParams ?? []))
		clause.subClauses!.forEach(subClause => {
			params.push(...(subClause.segmentation.segmentedParams ?? []))
		})
	})
	return params
}
export function addPrefixToSegmentedTextId(segmentedText: SegmentedText, prefix: string): SegmentedText {
	return segmentedText.map(([id, text, type]) => {
		return [`${prefix}-${id}`, text, type]
	})
}
export function fixTemplateIndexation(contractTemplate: ContractTemplateEntity): ContractTemplateEntity {
	/*
	clauseCodes should store the index of the clause instead if code
	*/
	contractTemplate.groups?.forEach(group => {
		group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.index == cc)?.code))
	})
	contractTemplate.clauses.forEach((clause, index) => {
		clause.index = (index + 1).toString()
		clause.segmentation = idfySegmentation(clause.segmentation, genIdFactory(clause.index))
		return clause
	})
	contractTemplate.groups?.forEach(group => {
		group.Group_ContractTemplate.clauseCodes = group.Group_ContractTemplate.clauseCodes.map(cc => (contractTemplate.clauses.find(cl => cl.code == cc)?.index))
	})
	return { ...contractTemplate }
}

/**
 * CompileSegmentedText
 */
export function compileSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): string {
	return segmentedText.map(([id, value, type], index) => {
		const nextSegment = segmentedText[index + 1]
		switch (type) {
			case SegmentedTextType.PARAM:
				const [paramName] = value.split('.')
				const param = segmentedParams.find(param => param.name === paramName)
				// if the param is not found then return the value as is
				if (!param) {
					return value
				}
				// in case the following segment is a text and not starting with space
				if ((nextSegment && nextSegment[2] === SegmentedTextType.PARAM) || (nextSegment && nextSegment[2] === SegmentedTextType.STATIC && !nextSegment[1].startsWith(" "))) {
					return `\$${value} `
				}
				return `\$${value}`;
			case SegmentedTextType.COMMENT:
				if (nextSegment && nextSegment[2] === SegmentedTextType.STATIC && !nextSegment[1].startsWith(" ")) {
					return `#{${value}} `
				}
				return `#{${value}}`;
			case SegmentedTextType.STATIC:
				return value;
			default:
				throw new Error(`Unsupported SegmentedTextType: ${type}`);
		}
	}).join('');
}

export function removeNotFoundParams(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.filter(param => {
			switch (param.type) {
				case 'boolean':
					return removeNotFoundParams(param.args?.textIfFalse, []).segmentedText.length
						&& removeNotFoundParams(param.args?.textIfTrue, []).segmentedText.length
				case 'enum':
					return param.args?.filter(arg => removeNotFoundParams(arg.text, []).segmentedText.length).length
				default:
					return true
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [segmentId, text, type] = e
		return type !== SegmentedTextType.PARAM || segmentedParams.find(param => param.name === text.slice(1).split('.')[0])
	})
	return { segmentedText: newSegmentedtext, segmentedParams }
}

export function idfySegmentedText(segmentedText: ClauseEntity['segmentation']['segmentedText'], genId: () => string): ClauseEntity['segmentation']['segmentedText'] {
	return segmentedText.map((segment, idx) => {
		return [genId(), segment[1], segment[2], segment[3]]
	})
}
export function styleSegmentation(oldSegmentation: ClauseEntity['segmentation'], styledTextSegmentation: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): ClauseEntity['segmentation']{
	let segmentedParams: SegmentedClauseParams = [];
	let segmentedText: SegmentedText = [];
	oldSegmentation.segmentedParams.forEach((paramSegment, idx) => {
		switch (paramSegment.type) {
			case 'boolean':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'boolean',
						args: {
							textIfFalse: styleSegmentation({segmentedText: paramSegment.args?.textIfFalse, segmentedParams: []}, styledTextSegmentation, deletedSegments).segmentedText,
							textIfTrue: styleSegmentation({segmentedText: paramSegment.args?.textIfTrue, segmentedParams: []}, styledTextSegmentation, deletedSegments).segmentedText
						}
					}
				)
			case 'enum':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'enum',
						args: paramSegment.args?.map((arg => {
							return {
								option: arg.option,
								text: styleSegmentation({segmentedText: arg.text, segmentedParams: []}, styledTextSegmentation, deletedSegments).segmentedText
							}
						})),
					}
				)
			default:
				return segmentedParams.push(paramSegment)
		}
	})
	oldSegmentation.segmentedText.forEach(([segmentId, segmentText, segmentType, segmentStyle], idx) => {
		const styledSegments = styledTextSegmentation.filter((styledSegment) => styledSegment[0] === segmentId)
		if(styledSegments.length > 0 && segmentType === SegmentedTextType.STATIC){
			styledSegments.forEach((styledSegment) => {
				segmentedText.push([segmentId, styledSegment[1], segmentType, styledSegment[3]])
			})
		}else{
			if(!deletedSegments.find((segment) => segment.id === segmentId)){
				segmentedText.push([segmentId, segmentText, segmentType, segmentStyle])
			}
			
		}
	});
	return {segmentedText, segmentedParams}
}
export function idfySegmentation(segmentation: ClauseEntity['segmentation'], genId: () => string): ClauseEntity['segmentation'] {
	segmentation = cleanSegmentation(segmentation.segmentedText, segmentation.segmentedParams)
	if(segmentation.segmentedText.length === 0){
		segmentation.segmentedText = [['tmp', '_', SegmentedTextType.STATIC]]
	}
	let segmentedText = idfySegmentedText(segmentation.segmentedText, genId)
	let segmentedParams: SegmentedClauseParams = [];
	segmentation.segmentedParams.forEach((paramSegment, idx) => {
		switch (paramSegment.type) {
			case 'boolean':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'boolean',
						args: {
							textIfFalse: idfySegmentedText(paramSegment.args?.textIfFalse ?? [], genId),
							textIfTrue: idfySegmentedText(paramSegment.args?.textIfTrue ?? [], genId)
						}
					}
				)
			case 'enum':
				return segmentedParams.push(
					{
						...paramSegment,
						type: 'enum',
						args: paramSegment.args?.map((arg => {
							return {
								option: arg.option,
								text: idfySegmentedText(arg.text, genId)
							}
						})),
					}
				)
			default:
				return segmentedParams.push(paramSegment)
		}
	})
	return { segmentedText, segmentedParams }
}


/**
 * search segment by id and change the text
 */
export function updateSegment(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, text: string): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	const updatedSegmentedText: SegmentedText = segmentedText.map(([segmentId, segmentText, segmentType]) => {
		return segmentId === id ? [segmentId, text, segmentType] : [segmentId, segmentText, segmentType];
	});

	const updatedSegmentedParams = segmentedParams.map(param => {
		switch (param.type) {
			case 'boolean':
				param.args.textIfFalse = updateSegment(param.args.textIfFalse, [], id, text).segmentedText;
				param.args.textIfTrue = updateSegment(param.args.textIfTrue, [], id, text).segmentedText;
				return param;

			case 'enum':
				param.args = param.args.map(arg => {
					return {
						option: arg.option,
						text: updateSegment(arg.text, [], id, text).segmentedText
					};
				})
				return param;
			default:
				return param;
		}
	});

	return { segmentedText: updatedSegmentedText, segmentedParams: updatedSegmentedParams };

}

/**
 * search insert a parameter inside a segment
 */

export const insertParamInSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, newParam: SegmentedClauseParam, definition: number, textBefore: string, textAfter: string, field: string, segments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): { segmentedText: any[], segmentedParams: SegmentedClauseParam[] } => {
	const generateNewId = (baseId: string, index: number): string => {
		const parts = baseId.split('-');
		parts[parts.length - 1] = index.toString();
		return parts.join('-');
	};
	const getIndexFromId = (id: string): number => {
		const parts = id.split('-');
		return parseInt(parts[parts.length - 1]);
	}

	const relatedSegments = segmentedText.filter(([segmentId]) => segmentId === id);

	// Segment with given id not found in root segmented then text search in the params
	if (!relatedSegments || relatedSegments.length === 0) {
		// insert the new param in the segmented params text
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: insertParamInSegment(param.args?.textIfFalse, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
							textIfTrue: insertParamInSegment(param.args?.textIfTrue, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: insertParamInSegment(arg.text, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
		switch (newParam.type) {
			case 'enum':
				const lastEnumOccurence = (segmentedParams as SegmentedEnumParam[]).find((par) => par.name == newParam.name && par.definition == definition - 1)
				segmentedParams.push({
					name: newParam.name,
					label: newParam.label,
					type: newParam.type,
					args: lastEnumOccurence ? lastEnumOccurence.args.map(arg => {
						return {
							option: arg.option,
							text: [['tmp', '_', SegmentedTextType.STATIC]]
						}
					}) : [],
					definition: definition
				})
				break;
			case 'boolean':
				segmentedParams.push({
					name: newParam.name,
					label: newParam.label,
					type: newParam.type,
					args: {
						textIfTrue: [['tmp', '_', SegmentedTextType.STATIC]],
						textIfFalse: [['tmp', '_', SegmentedTextType.STATIC]],
					},
					definition: definition
				})
				break;
			default:
				const exists = segmentedParams.find((par) => par.name == newParam.name)
				if (!exists)
					segmentedParams.push(newParam)
				break;
		}
		return { segmentedText, segmentedParams };
	}

	// Split the segment at the insertion index
	//const [segmentId, segmentText, segmentType] = segmentedText[segmentToUpdateIndex];

	// manage the changed elements
	//const updatedSegment = [segmentId, textBefore + " ", segmentType];
	let paramSegment: SegmentedText[number]
	switch (newParam.type) {
		case 'enum':
			paramSegment = [generateNewId(id, getIndexFromId(id) + 1), `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			const lastEnumOccurence = (segmentedParams as SegmentedEnumParam[]).find((par) => par.name == newParam.name && par.definition == definition - 1)
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: lastEnumOccurence ? lastEnumOccurence.args.map(arg => {
					return {
						option: arg.option,
						text: [['tmp', '_', SegmentedTextType.STATIC]]
					}
				}) : [],
				definition: definition
			})
			break;
		case 'boolean':
			paramSegment = [generateNewId(id, getIndexFromId(id) + 1), `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: {
					textIfTrue: [['tmp', '_', SegmentedTextType.STATIC]],
					textIfFalse: [['tmp', '_', SegmentedTextType.STATIC]],
				},
				definition: definition
			})
			break;
		case 'beneficial':
		case 'beneficial[]':
			paramSegment = [generateNewId(id, getIndexFromId(id) + 1), newParam.name + "." + field, SegmentedTextType.PARAM];
			const oldBeneficial = segmentedParams.find((par) => par.name == newParam.name)
			if (!oldBeneficial)
				segmentedParams.push(newParam)
			break;
		default:
			paramSegment = [generateNewId(id, getIndexFromId(id) + 1), newParam.name, SegmentedTextType.PARAM];
			const exists = segmentedParams.find((par) => par.name == newParam.name)
			if (!exists)
				segmentedParams.push(newParam)
			break;
	}
	const newSegments: ClauseEntity['segmentation']['segmentedText'] = []
	segmentedText.map(([segmentId, segmentText, segmentType, segmentStyle], idx) => {
		const styledSegments = segments.filter((segment) => segment[0] === segmentId)
		if(styledSegments && styledSegments.length > 0){
			styledSegments.forEach((segment) => {
				if(segment[0] === id){
					if(segment[2] === SegmentedTextType.STATIC){
						newSegments.push([segmentId, segment[1], segmentType, segment[3]])
					}
					if(segment[2] === SegmentedTextType.PARAM){
						newSegments.push(paramSegment)
					}
				}else{
					newSegments.push([segmentId, segment[1], segmentType, segment[3]])
				}
			})
		}else{
			if(!deletedSegments.find((segment) => segment.id === segmentId)){
			newSegments.push([segmentId, segmentText, segmentType, segmentStyle])
			}
		}
	})
	//const createdSegment = [generateNewId(id, getIndexFromId(id) + 2), " " + textAfter, segmentType];

	// Update the segmented text
	// const updatedSegmentedText = [
	// 	...segmentedText.slice(0, segmentToUpdateIndex),
	// 	updatedSegment,
	// 	paramSegment,
	// 	createdSegment,
	// 	...segmentedText.slice(segmentToUpdateIndex + 1).map(([segmentId, segmentText, segmentType]) => {
	// 		return [generateNewId(segmentId, getIndexFromId(segmentId) + 2), segmentText, segmentType];
	// 	}),
	// ];
	return { segmentedText: newSegments, segmentedParams: segmentedParams };

}
export type ValidationWarning = {
	message: string;
	templateCode: string;
	clauseCode: string;
	subClauseCode: string;
	paramName: string;
}
export type GenerateTemplateFromDocumentRequest = {
	file: Blob & { name: string };
	name: string;
	isScanned: boolean;
	level1Id?: TypeLevel1Entity['id'];
	level2Id?: TypeLevel2Entity['id'];
	level3Id?: TypeLevel3Entity['id'];
}

export function deleteParamInSegment(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], parameter: SegmentedClauseParam) {
	if (segmentedParams.length) {
		const filterSegParams = (p: SegmentedClauseParam) => parameter.name !== p.name;
		segmentedParams = segmentedParams.filter(filterSegParams);
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: deleteParamInSegment(param.args?.textIfFalse, [], parameter).segmentedText,
							textIfTrue: deleteParamInSegment(param.args?.textIfTrue, [], parameter).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: deleteParamInSegment(arg.text, [], parameter).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [id, text, type] = e
		if (parameter.type === "enum" || parameter.type === 'boolean') {
			// remove all the segments that contain the parameter name
			return type !== SegmentedTextType.PARAM || !text.includes(`${parameter.name}.`)
		}
		// default case
		return type !== SegmentedTextType.PARAM || text !== parameter.name
	})
	// handle beneficial case
	const cleanedSegmentedText = newSegmentedtext.map((segment) => {
		const [id, text, type] = segment
		if (type === SegmentedTextType.PARAM && text.includes(`${parameter.name}.`)) {
			const newType = SegmentedTextType.STATIC
			const newText = "(" + text.replace(`${parameter.name}.`, "") + ")"
			segment = [id, newText, newType]
		}
		return segment
	})

	return { segmentedText: cleanedSegmentedText, segmentedParams }
}

export function cleanSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: cleanSegmentation(param.args?.textIfFalse.length > 0 ? param.args.textIfFalse : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
							textIfTrue: cleanSegmentation(param.args?.textIfTrue.length > 0 ? param.args.textIfTrue : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: cleanSegmentation(arg.text.length > 0 ? arg.text : [["", "_", SegmentedTextType.STATIC]], []).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
	}
		// remove empty segments
		segmentedText = segmentedText.filter(segment => {
			const [segmentId, text, type] = segment
			return text.length > 0
		})
	// if first or last segments are not static add static segments
	if (segmentedText.length) {
		const firstSegment = segmentedText[0]
		const lastSegment = segmentedText[segmentedText.length - 1]
		if (firstSegment[2] !== SegmentedTextType.STATIC) {
			segmentedText.unshift(["id-first", " ", SegmentedTextType.STATIC])
		}
		if (lastSegment[2] !== SegmentedTextType.STATIC) {
			segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
		}
	}
	// if two consecutive segments are params then add a static segment between them
	const newSegmentedText = [] as SegmentedText
	for (let i = 0; i < segmentedText.length; i++) {
		const currentSegment = segmentedText[i]
		const nextSegment = segmentedText[i + 1]
		newSegmentedText.push(currentSegment)
		if (currentSegment[2] === SegmentedTextType.PARAM && nextSegment && nextSegment[2] === SegmentedTextType.PARAM) {
			newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
		}
	}
	// if two consecutive segments are static and share the same styles then merge them
	const mergedSegmentedText = [] as SegmentedText
	for (let i = 0; i < newSegmentedText.length; i++) {
		const currentSegment = newSegmentedText[i]
		const nextSegment = newSegmentedText[i + 1]
		const [segmentId, text, type, style] = currentSegment
		const [nextSegmentId, nextText, nextType, nextStyle] = nextSegment || []
		if (nextSegment 
			&& type === SegmentedTextType.STATIC 
			&& nextType === SegmentedTextType.STATIC 
			&& _.isEqual(style, nextStyle)
		) {
			mergedSegmentedText.push([segmentId, text + nextText, type, style])
			i++
		} else {
			mergedSegmentedText.push(currentSegment)
		}
	}
	return { segmentedText: mergedSegmentedText, segmentedParams }
}

export const deleteSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): { segmentedText: any[], segmentedParams: SegmentedClauseParam[] } => {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: deleteSegment(param.args?.textIfFalse, [], id, styledSegments, deletedSegments).segmentedText,
							textIfTrue: deleteSegment(param.args?.textIfTrue, [], id, styledSegments, deletedSegments).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: deleteSegment(arg.text, [], id, styledSegments, deletedSegments).segmentedText
							}
						})
					}
				default:
					return param
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [segmentId, text, type] = e
		return segmentId !== id
	})
	const newStyledSegmentedText: ClauseEntity['segmentation']['segmentedText'] = []
	  newSegmentedtext.map((segment) => {
		const [segmentId, text, type, style] = segment
		const foundSegments = styledSegments.filter((styledSegment) => styledSegment[0] === segmentId)
		if(foundSegments.length > 0){
			foundSegments.forEach((styledSegment) => {
				newStyledSegmentedText.push([segmentId, styledSegment[1], type, styledSegment[3] || style])
			})
		}else{
			if(!deletedSegments.find((segment) => segment.id === segmentId)){
				newStyledSegmentedText.push([segmentId, text, type, style])
			}
		}
	})
	//** */
	return { segmentedText: newStyledSegmentedText, segmentedParams }
}
import { Descendant } from 'slate'
import { RenderSegments, SegmentedText, SegmentedTextType, segmentedTextStyle } from '../../../../../domain/types/ClauseParams'
import { useEffect } from "react";
import { ClauseEntity } from '../../../../../domain/entities';

export const buildBlocks = (segments, params) => {
  const newBlocks: Descendant[] = []
  let children = []
  segments.forEach((segment, index) => {
    let isHighlighted = false;
    if (segment.type === SegmentedTextType.STATIC) {
      if (segment.isHighlighted) {
        isHighlighted = true
      } 
      const styles: segmentedTextStyle = segment.style || {}
      children.push({
        text: segment.value,
        id: segment.id,
        highlight: isHighlighted,
        ...styles
      })
    }
    if (segment.type === SegmentedTextType.PARAM) {
      // insert as mention
      const param = params.find(p => p.name === segment.paramName)
      const label = param?.label || ''
      const fullLabel = (param.type === 'beneficial' || param.type == 'beneficial[]') ? `${label}` + ` ${segment.value.split('.')[1] && `(${segment.value.split('.')[1]})`} ` : label
      children.push({
        type: 'mention',
        character: fullLabel,
        paramName: segment.value,
        id: segment.id,
        children: [{ text: segment.paramName }]
      })
    }
    if (segment.type === SegmentedTextType.COMMENT) {
      children.push({
        text: segment.value,
        id: segment.id,
        comment: true
      })
    }
  })
  newBlocks.push({
    type: 'paragraph',
    children
  })
  return newBlocks
}
export function useOutsideAlerter(ref, func, currentSegment) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, func, currentSegment]);
}
export function blockToSegment(block){
  if(block.type === 'mention'){
    return {
      id: block.id,
      value: block.character,
      paramName: block.paramName,
      type: SegmentedTextType.PARAM
    }
  }
  if(block.comment){
    return {
      id: block.id,
      value: block.text,
      type: SegmentedTextType.COMMENT
    }
  }
  return {
    id: block.id,
    value: block.text,
    type: SegmentedTextType.STATIC
  }
}
export function blocksToSegments(blocks) {
  const segments: ClauseEntity['segmentation']['segmentedText'] = []
  blocks.forEach(block => {
    block.children.forEach(child => {
      if (child.type === 'mention') {
        segments.push([
          child.id,
          child.paramName,
          SegmentedTextType.PARAM,
        ])
      } else {
        segments.push([
          child.id,
          child.text,
          SegmentedTextType.STATIC,
          {
            bold : child.bold,
            italic : child.italic,
            underline : child.underline,
          }
        ])
      }
    }
    )
  }
  )
  return segments
}

import React, { useContext, useState } from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import CustomCombobox from '../../../../components/common/CustomCombobox';
import CustomPropertiesContext from '../../../../contexts/CustomPropertiesContext';
function PropertyRender({ isEditing, value, valueName, onChange, param, page, onChangeName, errors }) {
  const {  onParamChanged } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();

  const [isOpen, setIsOpen] = useState(false)
  const { tags } = useContext(CustomPropertiesContext)
  const optionDisplay = (option) => {
    return option? t(`${translationTypes}.${option}`) :""; 
  };
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"
  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label">{translationPath.parameterName} : {param.name}</label>
        ) : (
          <>
            <textarea
              className="custom-form-textarea"
              value={valueName}
              onChange={onChangeName}
            />
            {
              errors.map((err, idx) => {
                if (err.where === "name") {
                  return <div key={idx} className="error-message-dialog">{err.why}</div>
                }
              })
            }
          </>
        )}
      </div>
      <div>
        {!isEditing ? (
          <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label" htmlFor={param.name}>
            {translationPath.parameterLabel} :  {param.label}
          </label>
        ) : (
          <textarea
            className="custom-form-textarea"
            name={param.name}
            value={value}
            onChange={onChange}
          />
        )}
        <div className="">
          {isEditing && (
            <div style={{width:"95%"}}>
            <CustomCombobox
              label={""}
              options={tags}
              fullWidth
              value={param?.args?.tag ?? ""}
              onChange={(value: string) => {
                param.args.tag = value
                onParamChanged(page.id, { ...param })
              }}
              optionDisplay={optionDisplay}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            </div>
          ) || (
              <label style={{ justifyContent: language === "ar" ? "right" : "left" }} className="custom-form-label mt-2" htmlFor={param.args.tag}>
                {translationPath.parameterTag}: {param.args.tag ? t(`${translationTypes}.${param.args.tag}`) : ""}
              </label>
            )
          }
        </div>
      </div>
    </div>

  )
}

export default PropertyRender
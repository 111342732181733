import "./formParameterInput.scss";
import FileInput from "../../../components/common/FileInput";
import { useContext, useEffect, useState } from "react";
import EditTableModal from "../../../components/EditTableModal";
import { extractXLSXCells } from "../../../utils/csv";
import { transpose } from "../../../utils/array";
import { BeneficialEntity, FormTemplatePageEntity } from "../../../domain/entities";
import { FormListInputItem } from "./FormListInputItem";
import { isFileType } from "../../../helpers/helper";
import EditFormContext from "../../../contexts/EditFormContext";
import FormDateInput from "./FormDateInput";
import { FormParam } from "../../../domain/types/FormParams";
import { useTranslation } from "../../../contexts/TranslationProvider";
import BeneficialForm from "./beneficialForm";
import FormNestedChildrenParams from "./FormNestedChildrenParams";
import { AiFillDelete } from "react-icons/ai";
import { evaluateParamConstraints, evaluateParamFormula } from "../../../domain/Form";
import AddBenificialModel from "../../../components/modals/AddBeneficialModal";
import CustomPropertiesContext from "../../../contexts/CustomPropertiesContext";
import CustomCombobox from "../../../components/common/CustomCombobox";
import { getAllParams } from "../../../domain/FormTemplate";
import { validNumberInput } from "../../../domain/validators";

function FormParameterInput({
  page,
  param,
  handleInputChange,
  setShowBeneficial,
  triggeredParam
}: {
  page: FormTemplatePageEntity;
  param: FormParam;
  setShowBeneficial: any;
  handleInputChange(paramValue: any, paramName: string): void;
  triggeredParam: string | null;
}) {
  const { beneficialsMap, setBeneficialsMap, fileNames, paramValues, template } = useContext(EditFormContext);
  let currentValue = paramValues[param.name];
  if ((param.type == 'number' || param.type == 'string') && param.formula) {
    currentValue = evaluateParamFormula(param, paramValues, getAllParams(template))
  }

  useEffect(() => {
    if ((param.type == 'number' || param.type == 'string') && param.formula) {
      currentValue = evaluateParamFormula(param, paramValues, getAllParams(template))
      handleInputChange(currentValue, param.name)
    }
  }, [currentValue])
  const currentTranspose = !!currentValue?.[0]
  const [isModalOpen, setIsModalOpen] = useState(false);

  let currentCells = param.type == "csv" ? currentValue?.[1] ?? [] : []
  const { t, language } = useTranslation();

  currentCells = currentTranspose ? transpose(currentCells) : currentCells
  const [showTableModal, setShowTableModal] = useState(false);
  const [showBenificialModel, setShowBenificialModel] = useState(false);
  const [beneficialToUpdate, setBeneficialToUpdate] = useState<BeneficialEntity>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [csvCells, setCsvCells] = useState(currentCells)
  const [isHighlighted, setIsHighlighted] = useState(false);

  const [isOpen, setIsOpen] = useState(false)
  const { propertiesGroup } = useContext(CustomPropertiesContext)
  const fieldName = `${page.id}_${param.name}`;
  const constraintResults = (param.type === "number" || param.type === "string" || param.type === 'date') ? evaluateParamConstraints(param, paramValues, getAllParams(template)) : null
  useEffect(() => {
    if (!triggeredParam) {
      setIsHighlighted(false)
      return
    }
    if (triggeredParam === param.name) {
      setIsHighlighted(true)
    } else {
      if (param.condition && param.condition.length > 0) {
        const isNested = param.condition.find((con) => con.find((c) => c.name === triggeredParam))
        if (isNested)
          setIsHighlighted(true)
        if (!isNested)
          setIsHighlighted(false)
      }
    }
  }, [triggeredParam]);

  switch (param.type) {
    case 'beneficial':
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <label className="edit-label-form" htmlFor={param.name}>{param.label}</label>
          <BeneficialForm
            beneficialsMap={beneficialsMap}
            setBeneficialsMap={setBeneficialsMap}
            currentBenificialData={beneficialsMap[currentValue] || {}}
            onSave={(beneficialId) => {
              handleInputChange(beneficialId, param.name,);
            }}
            beneficialTypes={param?.args?.beneficialTypes ?? ['Company', 'Person']}
          />
        </div>
      )
    case 'beneficial[]':
      const beneficialsId: BeneficialEntity['id'][] = Array.isArray(currentValue) && currentValue || []
      const beneficials = beneficialsId.map(id => beneficialsMap[id]).filter(b => b)
      return (
        <div
          className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
        >
          <div className="d-flex align-items-start justify-content-between py-2 px-2">
            <label htmlFor={param.name} className="ms-2">
              {param.label}
            </label>
            {beneficialsId.length > 0 ? (
              <div className="input-progress-done  ms-2">{t("pages.editionContract.complete")}</div>
            ) : (
              <div className="input-inprogres ms-2">{t("pages.editionContract.inProgress")}</div>
            )}
          </div>
          {beneficials?.length > 0 && (<div className="list-beneficial">
            {beneficials.map((beneficial, idx) => (
              <div key={`${beneficial.id}-${idx}`} className="item">

                <div onClick={() => setBeneficialToUpdate(beneficial)} className="beneficial-name">
                  {beneficial.name}
                </div>
                <div className="d-flex justify-content-between">
                  <div className={`type-tag`}>
                    {beneficial.type === "Person"
                      ? t("pages.editionContract.popups.benificial.type1")
                      : beneficial.type === "Company"
                        ? t("pages.editionContract.popups.benificial.type2")
                        : beneficial.type === "Minor" ?
                          t("pages.editionContract.popups.benificial.type3") :
                          beneficial.type === "Joint" ?
                            t("pages.editionContract.popups.benificial.type4") :
                            t("pages.editionContract.popups.benificial.type5")
                    }
                  </div>
                  <AiFillDelete
                    style={{
                      marginRight: "0.5rem",
                      color: "#e5142f",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let newValue = beneficialsId.filter(
                        (id) => id != beneficial.id
                      );
                      handleInputChange(
                        newValue,
                        param.name,
                      );
                    }}
                  />
                </div>
              </div>
            ))}
          </div>)}
          {beneficialToUpdate && (
            <AddBenificialModel
              onClose={() => setBeneficialToUpdate(null)}
              beneficialsMap={beneficialsMap}
              setBeneficialsMap={setBeneficialsMap}
              currentBenificialData={beneficialToUpdate}
              onSave={(beneficialId) => {
                setBeneficialToUpdate(null);
              }}
              beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person', 'Minor', 'Joint', 'Attorney']}
            />
          )}
          <div
          >
            <button
              type="button"
              className="benificial-button add-list-beneficials"
              onClick={() => {
                setShowBenificialModel(true);
              }}
            >
              {t("pages.editionContract.addBeneficial")}
            </button>
            {showBenificialModel && (
              <AddBenificialModel
                beneficialsMap={beneficialsMap}
                setBeneficialsMap={setBeneficialsMap}
                onSave={(beneficialId) => {
                  handleInputChange(
                    [...beneficialsId, beneficialId],
                    param.name,
                  );
                  setShowBenificialModel(false);
                }}
                onClose={() => setShowBenificialModel(false)}
                beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person']}
              />
            )}

          </div>
        </div>
      );
    case 'table':
      const currentTableValues: (string | Date | number)[][] = currentValue ?? [];
      let rowsTable = param?.args?.length > 0
      return (
        rowsTable && <>
          <button
            type="button"
            className={`benificial-button ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
            onClick={() => setShowTableModal(true)}
          >
            {param.label}
          </button>
          {showTableModal && (
            <EditTableModal
              param={param}
              onClose={() => setShowTableModal(false)}
              currentTableValues={currentTableValues}
              onSave={(tableData) => {
                handleInputChange(tableData, param.name,);
                // setShowBenificialModel(false);
              }}
            />
          )}
        </>
      );

    case 'boolean':
      const boolHasValue = currentValue !== null && currentValue !== undefined;
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100 justify-content-between">
            <label className="edit-label-form" htmlFor={param.name}>{param.label}</label>
            {boolHasValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>

          <div className="radio-group boolean-group">
            <label className="form-check-label">
              <input
                type="radio"
                value="true"
                checked={boolHasValue && currentValue}
                className="form-check-input boolean-check"
                onChange={() => {
                  handleInputChange(
                    true,
                    param.name,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionForm.yes")}
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                value="false"
                checked={boolHasValue && !currentValue}
                className="form-check-input boolean-check"
                onChange={(e) => {
                  handleInputChange(
                    false,
                    param.name,
                  );
                }}
                name={param.name}
              />
              {t("pages.editionForm.no")}
            </label>
          </div>
          <FormNestedChildrenParams
            page={page}
            param={param}
            setShowBeneficial={setShowBeneficial}
            handleInputChange={handleInputChange}
            triggeredParam={triggeredParam}
          />
        </div>
      );
    case 'date':
      return (
        <div
          className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
        >
          <div className="d-flex w-100 justify-content-between">
            <label htmlFor={param.name} className="edit-label-form">
              {param.label ?? "JJ/MM/AAAA"}
            </label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div>
            <div className="w-100">
              <FormDateInput
                name={fieldName}
                defaultValue={currentValue}
                onChange={(date) => {
                  if (date && !isNaN(date.getTime())) {
                    handleInputChange(date.toISOString().substring(0, 10), param.name,);
                  }
                }}
              />
            </div>
            {
              constraintResults && constraintResults.filter(c => !c[1]).length > 0 && <>
                <span className="error-message-dialog">
                  {t("pages.editionForm.unvalidConstraint")}:
                </span>
                {constraintResults.map((result, idx) => {
                  return !result[1] ? <div key={idx} className="error-message-dialog">{result[0]}</div> : ""
                })}</>
            }
          </div>
        </div>
      );
    case 'file':
      const displayFile = currentValue && (isFileType(currentValue) ? currentValue : { name: fileNames[currentValue] })
      const fileId = currentValue && Number(currentValue) || undefined
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100 justify-content-between">
            <label htmlFor={param.name} className="edit-label-form">
              {param.label}
            </label>

            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div className="d-flex gap-2">
            <div className="w-100">
              <FileInput
                onChange={(file) =>
                  handleInputChange(file, param.name)
                }
                value={displayFile}
                id={fileId}
                borderClassName={currentValue ? "green-border" : "grey-border"}
                onOpenModal={() => { setIsModalOpen(!isModalOpen) }}
                openModal={isModalOpen}
              />
            </div>
          </div>
        </div>
      );
    case 'csv':
      const onCSVInputChange = (file: File) => {
        if (file) {
          extractXLSXCells(file, (cells) => {
            setCsvCells(cells)
            handleInputChange([false, cells, file.name], param.name,)
          })
        } else {
          setCsvCells([])
          handleInputChange(null, param.name,)
        }
      }
      const onTranspose = (value: boolean) => {
        handleInputChange([value, value ? transpose(csvCells) : csvCells, currentValue[2]], param.name,)
      }
      return (
        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100 justify-content-between">
            <label htmlFor={param.name} className="edit-label-form">
              {param.label}
            </label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div className="d-flex gap-2">
            {currentValue && (
              <div className="switch w-10">
                <input
                  type="checkbox"
                  id={`toggle_${page.id}`}
                  onChange={e => onTranspose(e.target.checked)}
                />
                <label htmlFor={`toggle_${page.id}`}></label>
              </div>
            )}
            <div className="w-100">
              <FileInput
                onChange={onCSVInputChange}
                value={currentValue ? { name: currentValue[2] } : null}
                borderClassName={currentValue ? "green-border" : "grey-border"}
              />
            </div>

          </div>
        </div>
      );

    case 'list':
      const hasValueList = currentValue !== null && currentValue !== undefined && currentValue !== "" && currentValue.length > 0;
      let rowsList = param?.args?.length > 0
      return (

        rowsList && <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100  justify-content-between">
            <label className="edit-label-form" htmlFor={param.name}>{param.label}</label>
            {hasValueList ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div className="wrapper-item-list-form">
            {param.args && param.args.map((arg, index) => (
              <FormListInputItem
                currentValue={currentValue}
                key={index}
                index={index}
                handleInputChange={handleInputChange}
                param={param}
                colorLabel="#212529"
              />
            ))}
          </div>
        </div>
      )
    case 'property':
      const properties = propertiesGroup[param.args.tag] ?? []
      const hasPropertyValue = currentValue !== null && currentValue !== undefined && currentValue !== ""
      return (

        <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>
          <div className="d-flex w-100  justify-content-between">
            <label className="edit-label-form" htmlFor={param.name}>{param.label}</label>
            {hasPropertyValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <div className="wrapper-item-list-form">
            <CustomCombobox
              isOpen={isOpen}
              label={param.label}
              onChange={(value: string) => {

                handleInputChange(
                  value,
                  param.name,
                )
              }}
              options={properties.map(p => p.value)}
              setIsOpen={setIsOpen}
              value={currentValue}
            />
          </div>
        </div>
      )


    case 'enum':
      const enumOptions = param.args && param.args.map((arg) => arg.option);
      const hasValue = currentValue !== null && currentValue !== undefined && currentValue !== "";
      let rowsEnum = param?.args?.length > 0
      return (
        rowsEnum && <>
          <div className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}>

            <div className="d-flex w-100 justify-content-between">

              <label className="edit-label-form">{param.label}</label>
              {hasValue ? (
                <div className="input-progress-done ms-2">
                  {t("pages.editionForm.complete")}
                </div>
              ) : (
                <div className="input-inprogres ms-2">
                  {t("pages.editionForm.inProgress")}
                </div>
              )}
            </div>

            <div className="radio-group flex-column align-items-start w-100 pb-1" style={{ gap: "0rem" }}>

              {enumOptions && enumOptions.map((option, index) => (
                <div key={`${fieldName}_${index}`} className="form-check">
                  <div>
                    <input
                      type="radio"
                      className="form-check-input"
                      value={index}
                      checked={currentValue == index}
                      onChange={(e) => {
                        console.log(index, e.target.value);
                        handleInputChange(index, param.name);
                      }}
                    />
                    <label className="form-check-label" htmlFor={String(index)}>
                      {option}
                    </label>
                  </div>
                </div>
              ))}
            </div>
            <FormNestedChildrenParams
              page={page}
              param={param}
              setShowBeneficial={setShowBeneficial}
              handleInputChange={handleInputChange}
              triggeredParam={triggeredParam}
            />
          </div>
        </>
      );
    case 'string':
      return (
        <div
          className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
        >
          <div className="d-flex w-100 justify-content-between">
            <label className="edit-label-form">{param.label}</label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <textarea
            className="edit-input-form edit-input-form-text"
            value={currentValue}
            onChange={(e) =>
              handleInputChange(e.target.value, param.name)
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={(param as any).formula ? true : false}
          />
          {
            constraintResults && constraintResults.filter(c => !c[1]).length > 0 && <>
              <span className="error-message-dialog">
                {t("pages.editionForm.unvalidConstraint")}:
              </span>
              {constraintResults.map((result, idx) => {
                return !result[1] ? <div key={idx} className="error-message-dialog">{result[0]}</div> : ""
              })}</>
          }
        </div>
      );
    case 'number':

      return (
        <div
          className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
        >
          <div className="d-flex w-100 justify-content-between">
            <label className="edit-label-form">{param.label}</label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <input
            className="edit-input-form"
            disabled={(param as any).formula ? true : false}
            value={currentValue}
            onChange={(e) => {
              e.target.value = validNumberInput(e.target.value) ? e.target.value
                : validNumberInput(currentValue) ? currentValue
                  : ''
              handleInputChange(e.target.value, param.name)
            }
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {
            constraintResults && constraintResults.filter(c => !c[1]).length > 0 && <>
              <span className="error-message-dialog">
                {t("pages.editionForm.unvalidConstraint")}:
              </span>
              {constraintResults.map((result, idx) => {
                return !result[1] ? <div key={idx} className="error-message-dialog">{result[0]}</div> : ""
              })}</>
          }
        </div>
      );
    default:
      return (
        <div
          className={`input-param-form ${isHighlighted ? "triggered-input" : "non-trriggered"}`}
        >
          <div className="d-flex w-100 justify-content-between">
            <label className="edit-label-form">{param.label}</label>
            {currentValue ? (
              <div className="input-progress-done  ms-2">
                {t("pages.editionForm.complete")}
              </div>
            ) : (
              <div className="input-inprogres ms-2">
                {t("pages.editionForm.inProgress")}
              </div>
            )}
          </div>
          <input
            className="edit-input-form"
            disabled={(param as any).formula ? true : false}
            value={currentValue}
            onChange={(e) =>
              handleInputChange(e.target.value, param.name)
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
      );
  }
}
export default FormParameterInput;
